window.MainScript = function () {
  ;(function ($) {
    // dodawanie zer przed liczbą - uwaga - zamienia na string
    function pad(str, max) {
      str = str.toString()
      return str.length < max ? pad("0" + str, max) : str
    }

    function onInputFocus(ev) {
      classie.add(ev.target.parentNode, "input--filled")
    }

    function onInputBlur(ev) {
      if (ev.target.value.trim() === "") {
        classie.remove(ev.target.parentNode, "input--filled")
      }
    }

    function menu_callback() {
      id = $(this.triggerElement()).attr("id")
      var_index = $(this.triggerElement()).index(".faq-page .section")
      if ($(this.triggerElement()).hasClass("redbg")) {
        $(".faq-menu").addClass("whiteletters")
      } else {
        $(".faq-menu").removeClass("whiteletters")
      }
    }

    function blogscroll() {
      if ($(window).scrollTop() >= 60) {
        $(".blog-sort").addClass("fixieme")
      } else {
        $(".blog-sort").removeClass("fixieme")
      }
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
      // All pages
      common: {
        init: function () {
          var bLazy = new Blazy()

          // IE 11- fix skakanie
          if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // if IE
            $("body").on("mousewheel", function () {
              // remove default behavior
              event.preventDefault()

              //scroll without smoothing
              var wheelDelta = event.wheelDelta
              var currentScrollPosition = window.pageYOffset
              window.scrollTo(0, currentScrollPosition - wheelDelta)
            })
          }

          $("img.svg").each(function () {
            var $img = $(this)
            var imgID = $img.attr("id")
            var imgClass = $img.attr("class")
            var imgURL = $img.attr("src")

            $.get(
              imgURL,
              function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find("svg")

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== "undefined") {
                  $svg = $svg.attr("id", imgID)
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== "undefined") {
                  $svg = $svg.attr("class", imgClass + " replaced-svg")
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr("xmlns:a")

                // Replace image with new SVG
                $img.replaceWith($svg)
              },
              "xml"
            )
          })

          imagesLoaded("body", function () {
            $("#preloader").fadeOut("fast")
            $("body").removeClass("preload")
            if ($(document).width() >= 1024) {
              $("#square").addClass("animated").addClass("fadeIn")
              $("#start_screen .square .text").addClass("trans")
            }
          })

          $(document).foundation() // Foundation JavaScript
          var wow = new WOW({
            mobile: false,
          })
          wow.init()

          if ($(document).width() >= 1024) {
            var ctrl = new ScrollMagic.Controller()

            var photo_tween = TweenMax.to("#start_screen .photo_greyscale", 1, {
              "-webkit-filter": "grayscale(0)",
              filter: "grayscale(0)",
            })

            var top_desc_tween = TweenMax.to("#top_desc", 1, {opacity: 0})
            var top_desc_feat_tween = TweenMax.to("#top_desc_feat", 0.8, {
              opacity: 1,
            })
            var menu_tween = TweenMax.to("#header_nav", 1, {opacity: 1})

            var timeline = new TimelineMax()
            timeline.add(photo_tween)
            timeline.add(top_desc_tween)
            timeline.add(top_desc_feat_tween)
            timeline.add(menu_tween)
            // build scene
            var scene = new ScrollMagic.Scene({
              triggerElement: "#start_screen",
              duration: "100%",
              triggerHook: 0,
            })
              .setPin("#start_screen")
              .setTween(timeline)
              .addTo(ctrl)
          }

          // Na małych ekranach przesunięcie ekranu, tak żeby widać pole
          if ($(window).width() < 1024) {
            $("#wpisz-maila").on("focus", function () {
              var href = $(this).attr("id")
              href = "#" + href
              location.href = href
            })

            $("#wpisz-maila-side").on("focus", function () {
              var position = $(this).offset()
              $("body, html").animate({scrollTop: position.top - 75}, 1)
              return false
            })
          }

          $(".faq-menu a, .scroll_sections").click(function () {
            var href = $(this).attr("href")
            var position = $(href).position()
            $("body, html").animate({scrollTop: position.top - 75}, 500)
            return false
          })

          $(".select-chosen").niceSelect()

          $("#cookies-box .pokazdetale").click(function () {
            $("#cookies-box .detale").slideDown()
            $(this).hide()
          })

          $("#cookies-box .ukryjdetale").click(function () {
            $("#cookies-box .detale").slideUp()
            $("#cookies-box .pokazdetale").fadeIn()
          })

          var cookies_set = Cookies.get("cookies_set")
          if (cookies_set === "true") {
            $("#cookies-box").removeClass("cookies-show").addClass("cookies-hide")
            $(".socials-links").removeClass("socials-links-up")
          } else {
            $("#cookies-box").removeClass("cookies-hide").addClass("cookies-show")
            $(".socials-links").addClass("socials-links-up")
          }

          $("#cookies-box .close").click(function () {
            $("#cookies-box").removeClass("cookies-show").addClass("cookies-hide")
            $(".socials-links").removeClass("socials-links-up")
            Cookies.set("cookies_set", true)
          })

          WebFontConfig = {
            google: {
              families: [
                "Alegreya+Sans:400,400italic,300,300italic,700,700italic:latin,latin-ext",
                "Alegreya+Sans+SC:400,400italic,300,300italic,700,700italic:latin,latin-ext",
                "Mouse+Memoirs::latin,latin-ext",
              ],
            },
          }
          ;(function () {
            var wf = document.createElement("script")
            wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"
            wf.type = "text/javascript"
            wf.async = "true"
            var s = document.getElementsByTagName("script")[0]
            s.parentNode.insertBefore(wf, s)
          })()

          $(".image-link").magnificPopup({type: "image"})

          $(".search-box .search-icon").click(function () {
            $(".search-box").toggleClass("open")
            $(".search-icon").toggleClass("active")
          })

          $(".search-newimp-action #s").focusin(function () {
            $(this).parent().addClass("focused")
          })

          $(".search-newimp-action #s").focusout(function () {
            $(this).parent().removeClass("focused")
          })

          $(".search-newimp-action .search-icon").click(function () {
            $(".search-newimp-action").addClass("open")
          })

          $(".nsl-frame.transparent .send").hide()
          $(".nsl-frame.transparent .email").focusin(function () {
            $(".nsl-frame.transparent .send")
              .removeClass("fadeOutLeft")
              .removeClass("fadeOutRight")
              .removeClass("animated")
              .addClass("fadeInLeft")
              .addClass("animated")
              .show()
          })
          $(".nsl-frame.transparent .email").focusout(function () {
            $(".nsl-frame.transparent .send")
              .removeClass("fadeInLeft")
              .removeClass("animated")
              .addClass("fadeOutLeft")
              .addClass("animated")
              .show()
          })

          $(".nsl-frame.side .send").hide()
          $(".nsl-frame.side .email").focusin(function () {
            $(".nsl-frame.side .send")
              .removeClass("fadeOutLeft")
              .removeClass("fadeOutRight")
              .removeClass("animated")
              .addClass("fadeInLeft")
              .addClass("animated")
              .show()
          })

          $(".metoda_platnosci .button").click(function () {
            $(".metoda_platnosci .button").removeClass("active")
            $(this).addClass("active")
            $("#przelew_box, #podatek_box").hide()

            if ($(this).is("#przelew")) {
              $("#przelew_box").fadeIn()
            }

            if ($(this).is("#procent")) {
              $("#podatek_box").fadeIn()
            }

            $("#operator").val($(this).attr("id"))
          })

          $(".kwota_platnosci .button").click(function () {
            $(".kwota_platnosci .button").removeClass("active")
            $(this).addClass("active")
            $("#kwota").val($(this).text())
            $("#kwota").val()
          })

          $("#input-kwota").change(function () {
            $(".kwota_platnosci .button").removeClass("active")
            $("#kwota").val($(this).val())
          })

          $("#newsletter_send").click(function () {
            $("#sidebar_newsletter").submit()
            return false
          })
          $("#donate_form").on("submit", function () {
            var operator = $("#operator").val()

            if (operator === "payu" || operator === "paypal") {
              return false
            } else {
              return true
            }
          })

          $("#send_form").click(function () {
            $("#donate_form").submit()
            return false
          })

          $("[data-abide]").on("formvalid.zf.abide", function () {
            let action = ""
            let form_action = ""
            const operator = $("#operator").val()
            if (operator === "payu") {
              action = "payu_ajax"
              form_action = "https://secure.payu.com/paygw/UTF/NewPayment"
            } else if (operator === "paypal") {
              action = "paypal_ajax"
              form_action = "https://www.paypal.com/cgi-bin/webscr"
            }

            if (operator === "payu" || operator === "paypal") {
              $("#pay_form").attr("action", form_action)
              const data = {
                action: action,
                "input-imie": $("#input-imie").val(),
                "input-nazwisko": $("#input-nazwisko").val(),
                "kwota": $("#kwota").val(),
                "operator": $("#operator").val(),
                "input-email": $("#input-email").val(),
                "input-lang": $("#input-lang").val(),
              }

              $.post(
                'https://donate.sasafoundation.com',
                data,
                function (response) {
                  $("#pay_form").html(response)
                  $("#pay_form").submit()
                }
              )
              return false
            }
          })

          $(".height100 .scroll_down").click(function () {
            $("html,body").animate(
              {scrollTop: $(".height100").height() - 150},
              "slow"
            )
            return false
          })

          /*$( window ).scroll( function(){
              blogscroll();
          });*/

          //player +playlista
          $(document).ready(function () {
            $("ul#videos li a").click(function (e) {
              e.preventDefault()
              $("#video").attr("src", $(this).attr("href"))
            })
          })

          //dynamic archive posts
          function search_form() {
            var date = $("#month-choice option:selected").val()
            var cat = $("#cat").val()
            var search = $("#search_box").val()
            var data = {
              action: "ajax_posts",
              date: date,
              select_c: cat,
              search: search,
            }

            $.post(ajax_url, data, function (response) {
              $("#ajaxcontent").html(response)
              data = $("#ajax-load-more .alm-listing").data()
              $.fn.almFilter("fade", 300, data)
            })
            return false
          }

          function search_form_small() {
            var search = $("#search_box_small").val()
            var data = {
              action: "ajax_posts",
              search: search,
            }

            $.post(ajax_url, data, function (response) {
              $("#ajaxcontent").html(response)
              data = $("#ajax-load-more .alm-listing").data()
              $.fn.almFilter("fade", 300, data)
            })
            return false
          }

          $("#archive-browser select").change(function () {
            search_form()
          })

          $("#search_form").submit(function () {
            search_form_small()
            return false
          })

          $("#search_form_small").submit(function () {
            search_form_small()
            return false
          })

          $.fn.almDone = function () {
            $("button#load-more").hide()
          }

          // scrollowanie za pomocą strzałek w Square
          $("#start_screen .arrows").click(function () {
            $("html,body").animate(
              {scrollTop: 2 * $("#start_screen").height()},
              "slow"
            )
          })

          imagesLoaded(
            ".partners-slider, .patron-slider, .quotes-slider",
            function () {
              $(".partners-slider, .patron-slider, .quotes-slider").flickity({
                cellAlign: "left",
                contain: true,
                wrapAround: true,
                pageDots: false,
                autoPlay: true,
                arrowShape: {
                  x0: 10,
                  x1: 60,
                  y1: 50,
                  x2: 60,
                  y2: 35,
                  x3: 25,
                },
              })
            }
          )

          imagesLoaded(".icons-slider", function () {
            $(".icons-slider").flickity({
              cellAlign: "center",
              contain: true,
              wrapAround: true,
              pageDots: false,
              //autoPlay: true,
              arrowShape: {
                x0: 10,
                x1: 60,
                y1: 50,
                x2: 60,
                y2: 35,
                x3: 25,
              },
            })
          })

          imagesLoaded(".helpers-slider", function () {
            var helpers_slider = $(".helpers-slider").flickity({
              cellAlign: "left",
              contain: true,
              wrapAround: true,
              pageDots: true,
              prevNextButtons: true,
              autoPlay: false,
              arrowShape: {
                x0: 10,
                x1: 60,
                y1: 50,
                x2: 60,
                y2: 35,
                x3: 25,
              },
            })

            var dot_count = 1
            var dots = $(".helpers-slider .flickity-page-dots li")
            $.each(dots, function () {
              $(this).text(pad(dot_count, 2))
              dot_count = dot_count + 1
            })

            var dots_ul = $(".helpers-slider .flickity-page-dots")
            dots_ul.prepend(
              '<li class="dot arrow-dot"><span class="prev_dot">&laquo;</span></li>'
            )
            dots_ul.append(
              '<li class="dot arrow-dot"><span class="next_dot">&raquo;</span></li>'
            )

            $(".prev_dot").on("click", function () {
              helpers_slider.flickity("previous")
            })

            $(".next_dot").on("click", function () {
              helpers_slider.flickity("next")
            })
          })

          var offsetTop = null

          var getOffsetTop = function () {
            offsetTop = $(window).scrollTop()
          }

          var setOffsetTop = function () {
            $("html, body").scrollTop(offsetTop)
          }

          getOffsetTop()

          $(".mobilemenu .mobilemenu_btn").addClass("open")

          $(".header_nav .mobilemenu_btn").click(function () {
            $(this).addClass("open")
            setTimeout(function () {
              getOffsetTop()
              $("html,body").addClass("is-opened-mobile")
            }, 700)
          })

          $(".mobilemenu_close").click(function () {
            $(".header_nav .mobilemenu_btn").removeClass("open")
            setOffsetTop()
            $("html,body").removeClass("is-opened-mobile")
          })

          // pokazywanie menumobile
          $(".mobilemenu_btn_open").click(function () {
            $("body").addClass("preload")
            setTimeout(function () {
              getOffsetTop()
              $("html,body").addClass("is-opened-mobile")
            }, 700)

            // dodanie animacji animate css na pokazywanym menu
            $(".mobilemenu")
              .removeClass("fadeOut")
              .removeClass("animated")
              .addClass("fadeIn")
              .addClass("animated")
              .show()

            $(".menu_content").css({opacity: "0"})
            // $(".menu_content, .mobilemenu_close").css({'opacity' : '0'});
            $(
              ".mobilemenu .inner .l2, .mobilemenu .inner .l4, .mobilemenu .inner .l1, .mobilemenu .inner .l3"
            ).css({opacity: "0"})

            // pokazywanie elmentów w menumobile z opoznieniem
            setTimeout(function () {
              $(".menu_content").animate({opacity: 1}, 1000)
            }, 200)

            setTimeout(function () {
              $(
                ".mobilemenu .inner .l2, .mobilemenu .inner .l4, .mobilemenu .inner .l1, .mobilemenu .inner .l3"
              ).animate({opacity: 1}, 1000)
            }, 0)

            // setTimeout(
            // function()
            // {
            //   $(".mobilemenu_close").animate({opacity: 1}, 1000);
            // }, 600);

            return false
          })

          // pokazywanie borderpopup
          $(".open-borderpopup").click(function () {
            $("body").addClass("preload")
            setTimeout(function () {
              getOffsetTop()
              $("html,body").addClass("is-opened-mobile")
            }, 700)

            $(".nsl-frame.transparent .send").hide()

            var popup_id = $(this).data("borderpopup")
            popup_id = "#" + popup_id
            // dodanie animacji animate css na pokazywanym popupie
            $(popup_id)
              .removeClass("fadeOut")
              .removeClass("animated")
              .addClass("fadeIn")
              .addClass("animated")
              .show()

            // $(popup_id +" .borderpopup_text, "+ popup_id +" .borderpopup_close, "+ popup_id +" .sponsor").css({'opacity' : '0'});
            $(popup_id + " .borderpopup_text, " + popup_id + " .sponsor").css({
              opacity: "0",
            })
            $(
              popup_id +
              ".borderpopup .inner .l1, " +
              popup_id +
              ".borderpopup .inner .l2, " +
              popup_id +
              ".borderpopup .inner .l3, " +
              popup_id +
              ".borderpopup .inner .l4"
            ).css({opacity: "0"})

            // pokazywanie elmentów z opoznieniem
            setTimeout(function () {
              $(popup_id + " .borderpopup_text").animate({opacity: 1}, 1000)
            }, 200)

            setTimeout(function () {
              $(
                popup_id +
                ".borderpopup .inner .l1, " +
                popup_id +
                ".borderpopup .inner .l2, " +
                popup_id +
                ".borderpopup .inner .l3, " +
                popup_id +
                ".borderpopup .inner .l4"
              ).animate({opacity: 1}, 1000)
            }, 0)

            setTimeout(function () {
              $(popup_id + " .sponsor").animate({opacity: 1}, 1000)
            }, 300)

            // setTimeout( function() {
            //   $(popup_id +" .borderpopup_close").animate({opacity: 1}, 1000);
            // }, 600);

            return false
          })

          // zamykanie menumobile
          $(".mobilemenu_close").click(function () {
            $("body").removeClass("preload")
            $("html,body").removeClass("is-opened-mobile")
            setOffsetTop()
            $(".mobilemenu")
              .removeClass("fadeIn")
              .removeClass("animated")
              .addClass("fadeOut")
              .addClass("animated")

            setTimeout(function () {
              $(".mobilemenu").hide()
            }, 300)

            return false
          })

          // zamykanie borderpopup
          $(".borderpopup_close").click(function () {
            $("body").removeClass("preload")
            $("html,body").removeClass("is-opened-mobile")
            setOffsetTop()
            var popup_this = $(this).parent().parent(".borderpopup")
            popup_this
              .removeClass("fadeIn")
              .removeClass("animated")
              .addClass("fadeOut")
              .addClass("animated")
            $(".mobilemenu_close").trigger("click")
            setTimeout(function () {
              $("html,body").removeClass("is-opened-mobile")
              setOffsetTop()
            }, 300)

            setTimeout(function () {
              popup_this.hide()
            }, 500)

            return false
          })
        },
        finalize: function () {
          // JavaScript to be fired on all pages, after page specific JS is fired

          ;(function () {
            // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
            if (!String.prototype.trim) {
              ;(function () {
                // Make sure we trim BOM and NBSP
                var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
                String.prototype.trim = function () {
                  return this.replace(rtrim, "")
                }
              })()
            }

            ;[].slice
              .call(document.querySelectorAll("input.input__field"))
              .forEach(function (inputEl) {
                // in case the input is already filled..
                if (inputEl.value.trim() !== "") {
                  classie.add(inputEl.parentNode, "input--filled")
                }

                // events:
                inputEl.addEventListener("focus", onInputFocus)
                inputEl.addEventListener("blur", onInputBlur)
              })
          })()
        },
      },
      // Home page
      home: {
        init: function () {
          // JavaScript to be fired on the home page

          // countJS licznik na str glownej
          var options = {
            useEasing: false,
            useGrouping: true,
            separator: ",",
            decimal: ".",
            prefix: "",
            suffix: " x",
          }

          var ids = ["count_1", "count_2", "count_3", "count_4", "count_5"]
          var countups = []
          var value = []
          var countup_run = false
          $.each(ids, function (i, val) {
            value[i] = $("#" + val).text()
            countups[i] = new CountUp(val, 0, value[i], 0, 2, options)
          })

          $(window).scroll(function () {
            if (
              $(window).scrollTop() + $(window).height() >=
              $("#sprzet #count_1").offset().top &&
              countup_run === false
            ) {
              countup_run = true
              $.each(ids, function (i) {
                countups[i].reset()
                countups[i].start()
              })
            }
          })
        },
        finalize: function () {
          // JavaScript to be fired on the home page, after the init JS
        },
      },
      faq: {
        init: function () {
          $(window).scroll(function () {
            $(".faq-page .section").each(function () {
              var div = $(this).offset()
              var div_height = $(this).height()
              var scroll = $(window).scrollTop() + 0.5 * $(window).height()
              if (scroll > div.top && scroll < div.top + div_height) {
                var index_i = $(this).index(".faq-page .section")
                $(".faq-menu a").removeClass("active")
                $(".faq-menu a").eq(index_i).addClass("active")

                if ($(this).hasClass("redbg")) {
                  $(".faq-menu").addClass("whiteletters")
                } else {
                  $(".faq-menu").removeClass("whiteletters")
                }
              }
            })
          })
        },
        finalize: function () {
          // JavaScript to be fired on the home page, after the init JS
        },
      },
      // About us page, note the change from about-us to about_us.
      nasze_dzialanie: {
        init: function () {
          // countJS licznik na Nasze działania
          var options = {
            useEasing: false,
            useGrouping: true,
            separator: ",",
            decimal: ".",
            prefix: "",
            suffix: " x",
          }

          var ids = ["count_1", "count_2", "count_3", "count_4", "count_5"]
          var countups = []
          var value = []
          var countup_run = false
          jQuery.each(ids, function (i, val) {
            value[i] = $("#" + val).text()
            countups[i] = new CountUp(val, 0, value[i], 0, 2, options)
            countups[i].reset()
          })

          $(window).scroll(function () {
            if (
              $(window).scrollTop() >= $(".height100").height() / 2 &&
              countup_run === false
            ) {
              jQuery.each(ids, function (i, val) {
                countups[i].reset()
                countups[i].start()
                countup_run = true
              })
            }
          })
        },
      },
    }

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function (func, funcname, args) {
        var fire
        var namespace = Sage
        funcname = funcname === undefined ? "init" : funcname
        fire = func !== ""
        fire = fire && namespace[func]
        fire = fire && typeof namespace[func][funcname] === "function"

        if (fire) {
          namespace[func][funcname](args)
        }
      },
      loadEvents: function () {
        // Fire common init JS
        UTIL.fire("common")

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
          i,
          classnm
        ) {
          UTIL.fire(classnm)
          UTIL.fire(classnm, "finalize")
        })

        // Fire common finalize JS
        UTIL.fire("common", "finalize")
      },
    }

    // Load Events
    $(document).ready(UTIL.loadEvents)

    $(document).ready(function () {
      $(window).trigger("resize")
    })

    $(window).load(function () {
      $(window).trigger("resize")
    })
  })(jQuery) // Fully reference jQuery after this point.
}
